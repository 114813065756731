export const formatGameDate = (gameDate) => {
  console.log("formatGameDate input:", gameDate); // Log the input

  if (!gameDate || typeof gameDate !== "string") {
    console.error("Invalid gameDate:", gameDate);
    return "Invalid Date";
  }

  // Parse the date as UTC
  const date = new Date(gameDate);
  if (isNaN(date.getTime())) {
    console.error("Invalid date object created:", gameDate);
    return "Invalid Date";
  }

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // Use UTC methods to extract date components
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = months[date.getUTCMonth()];

  return `${day} ${month}`;
};

export const formatGameTime = (gameTime) => {
  if (!gameTime || typeof gameTime !== "string") {
    console.error("Invalid gameTime:", gameTime);
    return "Invalid Time";
  }

  const [hours, minutes] = gameTime.split(":").map(Number);
  if (isNaN(hours) || isNaN(minutes)) {
    console.error("Invalid time components:", gameTime);
    return "Invalid Time";
  }

  const period = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}${period}`;
};
